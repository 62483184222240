
import { LibButton } from '@adidas-com-front/ui-library';
import { bannerClick, getLinkType } from '../track';
import { handleClickLink, handleHref } from '~/components/clp/util';
export default {
  name: 'Banner',
  components: {
    LibButton
  },
  props: {
    position: {
      type: Number,
      default: 1
    },
    // 组件数据源对象
    bannerData: {
      type: Object,
      default: () => {
        return {};
      }
    },
    // 埋点
    sensorInfo: {
      type: Object,
      default: () => ({})
    },
    length: {
      type: Number
    },
    buttonStyle: {
      type: String
    }
  },
  data() {
    return {
       // 当前环境是否为手机端
      isMobile: false,
      // 图片是否加载完成
      isLoad: true
    };
  },
  computed: {
    // 配置按钮样式
    btnStyles() {
      return this.isMobile ? 'filled' : 'unfilled';
    },

    // 图片加载态
    imgLoad() {
      return this.isLoad && 'fadein';
    },

    // 配置a标签href
    handleUrl() {
      if (!this.bannerData.photoLink) {return 'javascript:void(0);';}
      return handleHref(this.bannerData.photoLink);
    }
  },
  mounted() {
    this.isMobile = window.innerWidth < 720;
    this.isLoad = false;
    window.addEventListener('resize', this.identifyDevice);
  },
  destroyed() {
    window.removeEventListener('resize', this.identifyDevice);
  },
  methods: {

    // 埋点
    sensorClickTrack(type) {
      const { bannerPosition } = this.sensorInfo;
      const { photoLink, headDescription, buttonLink } = this.bannerData;
      const linkType = getLinkType(type === 'Image' ? photoLink : buttonLink);
      const bannerId = 'FeedCom_' + bannerPosition + '_' + 'total' + this.length;

      bannerClick({
        bannerClickType: type,
        bannerId,
        bannerName: headDescription,
        bannerPosition,
        bannerToPageType: linkType,
        bannerToUrl: type === 'Image' ? photoLink : buttonLink,
        roundBannerPosition: this.position?.toString()
      });
    },
    identifyDevice() {
      this.isMobile = window.innerWidth < 720;
    },

    // 图片点击事件
    imgClick(e, url) {
      if (!url) { return; }
      e.preventDefault();
      handleClickLink.call(this, { link: url });
      try {
        this.sensorClickTrack('Image');
      } catch (error) {
        console.log('feedCom click sensors image 报错', error);
      }
      this.$emit('stopLoop');
    },

    // 按钮点击事件
    btnClick(url) {
      if (!url) { return; }
      handleClickLink.call(this, { link: url });
      try {
        this.sensorClickTrack('Button');
      } catch (error) {
        console.log('feedCom click sensors button 报错', error);
      }
      this.$emit('stopLoop');
    },

    // 图片加载完成时执行事件
    onLoad() {
      this.isLoad = true;
    }
  }
};
