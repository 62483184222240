
import deepcopy from 'deepcopy';
// import { bannerView } from '../track';
import carouselCard from './carousel-card.vue';
import { detectOS } from '~/utilities';

export default {
  name: 'FeedComCarousel',
  components: {
    carouselCard
  },
  props: {
    // 组件数据源对象
    carouselDataList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    // 埋点
    sensorInfo: {
      type: Object,
      default: () => ({})
    },
    buttonStyle: {
      type: String
    }
  },
  data() {
    return {
      startSmallDotIndex: null,
      endSmallDotIndex: null,
      // swiper的第几个
      swiperIndex: 0,
      // 真实数据的第几个
      dataIndex: 0,
      distLeft: 0,
      current: 0,
      swiperOptionLoop: {
        // spaceBetween: 4,
        // loopAdditionalSlides: 2,
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
          enabled: true
        },
        loop: this.carouselDataList.length > 1,
        on: {
          slideChange: () => {
            const dataLength = this.carouselData.length;
            const current = this.swiper.realIndex;
            if (dataLength > 2 && !current && this.swiperIndex === dataLength - 1) {
              this.swiperIndex = 0;
              this.dataIndex = 0;
            }
            if (dataLength > 2 && current === dataLength - 1 && !this.swiperIndex) {
              this.swiperIndex = dataLength - 2;
              this.dataIndex = dataLength - 2;
            }
            if ([-1, 2].includes(current - this.swiperIndex)) {
              this.swiperIndex = this.getLastIndex(this.swiperIndex, dataLength);
              this.dataIndex = this.getLastIndex(this.dataIndex, dataLength);
            } else if ([1, -2].includes(current - this.swiperIndex)) {
              this.swiperIndex = this.getNextIndex(this.swiperIndex, dataLength);
              this.dataIndex = this.getNextIndex(this.dataIndex, dataLength);
            }

            if (dataLength <= 6) {
              // 图片不超过7张 没有滚动
              this.current = this.dataIndex;
              return;
            }

            if (this.dataIndex > 4 && ((this.dataIndex <= this.current) || (!this.current && this.dataIndex === dataLength - 1))) {
              // 大于第四张且往左滑动(或者loop从第一张滑动到最后一张)，indicatorDots需要向右
              this.current = this.dataIndex;
              this.changeRight();
            } else if (this.dataIndex > 4 && this.dataIndex > this.current) {
              // 大于第4张图片往右滑动 indicatorDots需要向左
              this.current = this.dataIndex;
              this.changeLeft();

            } else if (this.dataIndex <= 4) {
              // 滑动前四张 indicatorDots 不需要滑动
              this.distLeft = 0;
              this.endSmallDotIndex = 5;
              if (this.dataIndex >= 3) {
                this.startSmallDotIndex = 0;
              } else if (this.dataIndex < 3) {
                // dataIndex <3 是不需要展示startSmallDot
                this.startSmallDotIndex = null;
              }
            }
            this.current = this.dataIndex;
          },
          // 回调函数，swiper从一个slide过渡到另一个slide结束时执行。
          slideChangeTransitionEnd () {
            // 在Loop模式下Swiper切换到指定slide。切换到的是slide索引是realIndex
            // this.slideToLoop(this.realIndex, 0, false);
          }
        },
        pagination: {
          el: '.swiper-pagination'
          // dynamicBullets: this.carouselData.length > 6,
          // dynamicMainBullets: 4
        }
      },
      carouselData: deepcopy(this.carouselDataList)
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper?.swiper;
    },
    indicatorStyle() {
      return {
        transform: `translateX(${this.distLeft}px)`};
    }
  },
  watch: {
    'startSmallDotIndex': {
      handler () {
        // 当小圆点位置变化时 需要更新小圆点为大圆点
        this.upDataDotsStyle();
      },
      immediate: true
    },
    'endSmallDotIndex': {
      handler () {
        // 当小圆点位置变化时 需要更新小圆点为大圆点
        this.upDataDotsStyle();
      },
      immediate: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.swiper) {
        this.swiper.params.speed = detectOS() === 'Mobile' ? 500 : 800;
      }

      if (this.dataIndex >= this.carouselData.length) {
        this.dataIndex = this.dataIndex >= this.carouselData.length ? 0 : this.dataIndex;
        this.swiperIndex = this.dataIndex >= this.carouselData.length ? 0 : this.swiperIndex;
      }
      if (this.carouselData.length > 6) {
        this.endSmallDotIndex = 5;
      }
      this.upDataDotsStyle();
    });
    // this.sensorViewTrack();
  },
  destroyed() {
    this.swiper?.destroy(false, false);
  },
  methods: {
    // sensorViewTrack() {
    //   const { bannerPosition } = this.sensorInfo;
    //   const links = [];
    //   const headDescriptions = [];
    //   this.carouselData.forEach((item, index) => {
    //     headDescriptions.push(item.headDescription);
    //     links.push(item.photoLink || item.buttonLink);
    //   });
    //   const headDescription = headDescriptions.join('|');
    //   const bannerId = 'FeedCom_' + bannerPosition + '_' + 'total' + this.carouselData.length;
    //   const link = links.join('|');
    //   bannerView(
    //     bannerId,
    //     headDescription,
    //     bannerPosition,
    //     link
    //   );
    // },
    handleStopLoop() {
      if (this.swiper) {
        this.swiper.params.autoplay.enabled = false;
        this.swiper.params.autoplay.disableOnInteraction = false;
        this.swiper.autoplay.stop();
      }
    },
    // 更新小圆点的位置
    upDataDotsStyle() {
      this.carouselData.forEach((item, index) => {
        if (index === this.startSmallDotIndex || index === this.endSmallDotIndex) {
          item.showSmallDot = true;
        } else {
          item.showSmallDot = false;
        }
      });
      this.$forceUpdate();
    },
    changeRight() {
      if (this.carouselData.length - 1 === this.current) {
        // 倒数第一个 从第一个滑动到倒数第一个
        this.startSmallDotIndex = this.current - 5;
        this.endSmallDotIndex = null;
        this.distLeft = -(this.current - 5) * 9;
        return;
      }
      if (this.current === this.carouselData.length - 2) {
        // 倒数第二个 不需要显示endSmallDot
        this.startSmallDotIndex = this.current - 4;
        this.endSmallDotIndex = null;
        return;
      }
      // 圆的直径为5 margin-left=4 所以每次平移9px
      this.distLeft = this.distLeft + 9;
      this.startSmallDotIndex = this.current - 4;
      this.endSmallDotIndex = this.current + 1;

    },
    changeLeft() {
      if (this.current < this.carouselData.length - 1) {
        // 没有到达最后一个
        const distLeftTemp = this.distLeft - 9;
        this.distLeft = distLeftTemp;
        this.startSmallDotIndex = this.current - 4;
        this.endSmallDotIndex = this.current + 1;
        if (this.current === this.carouselData.length - 2) {
          // 倒数第二个
          this.endSmallDotIndex = null;
        }

      } else {
        // 移到最后一个点
        this.startSmallDotIndex = this.current - 5;
        this.endSmallDotIndex = null;
        this.distLeft = -(this.current - 5) * 9;
      }
    },
    getLastIndex(index, totalLength) {
      return index - 1 === -1 ? totalLength - 1 : index - 1;
    },
    getNextIndex(index, totalLength) {
      return index + 1 === totalLength ? 0 : index + 1;
    }
  }
};
